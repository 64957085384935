import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

export const Section = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 40px;
  font-size: 18px;
`

const DataPolicyPage = () => {
  const images = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "legal-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      smallHeader={true}
      title={"RECHTLICHES"}
      image={images.header.childImageSharp.fluid}
    >
      <SEO
        title="Datenschutz"
        description={
          "Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse. Im Folgenden informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können."
        }
      />
      <Section>
        <h2>Impressum</h2>

        <p>MainOrder GmbH<br/>
          Industriestr. 1<br/>
          65760 Eschborn<br/>
          Germany<br/></p>


          <p>Vertretungsberechtigte Geschäftsführer: Thomas Lang, Pascal Raszyk
            <br/>
            E-Mail: legal@mainorder.de<br/>
            Tel.: +49 6196 5230512<br/>
          </p>

        <p>Registergericht: Amtsgericht Frankfurt am Main<br/>
          Registernummer: HRB 120464<br/>
          Inhaltlich Verantwortlicher: Thomas Lang, Pascal Raszyk</p>
        <hr/>
        <h2>Datenschutz</h2>
        <h3>
          1) Information &uuml;ber die Erhebung personenbezogener Daten und
          Kontaktdaten des Verantwortlichen
        </h3>

        <p>
          <b>1.1</b>&thinsp;Wir freuen uns, dass Sie unsere Website besuchen und
          bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie
          &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung
          unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        </p>

        <p>
          <b>1.2</b>&thinsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf
          dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist
          KEGA GmbH, Industriestr. 1, 65760 Eschborn, Deutschland,
          Tel.: +49 6196 5230512, E-Mail: legal@mainorder.de. Der f&uuml;r die
          Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige
          nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit
          anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten entscheidet.
        </p>

        <p>
          <b>1.3</b>&thinsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und
          zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer
          vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den
          Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie
          k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge
          &bdquo;https://&ldquo; und dem Schloss-Symbol in Ihrer Browserzeile
          erkennen.
        </p>

        <h3>2) Datenerfassung beim Besuch unserer Website</h3>

        <p>
          Bei der blo&szlig; informatorischen Nutzung unserer Website, also wenn
          Sie sich nicht registrieren oder uns anderweitig Informationen
          &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an
          unseren Server &uuml;bermittelt (sog. &bdquo;Server-Logfiles&ldquo;).
          Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die
          f&uuml;r uns technisch erforderlich sind, um Ihnen die Website
          anzuzeigen:
        </p>

        <ul>
          <li>Unsere besuchte Website</li>
          <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
          <li>Menge der gesendeten Daten in Byte</li>
          <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
          <li>Verwendeter Browser</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
        </ul>

        <p>
          Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
          auf Basis unseres berechtigten Interesses an der Verbesserung der
          Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine
          Weitergabe oder anderweitige Verwendung der Daten findet nicht statt.
          Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich
          zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine
          rechtswidrige Nutzung hinweisen.
        </p>

        <h3>3) Cookies</h3>

        <p>
          Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
          bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf
          verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um
          kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden.
          Einige der von uns verwendeten Cookies werden nach dem Ende der
          Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder
          gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf
          Ihrem Endger&auml;t und erm&ouml;glichen, Ihren Browser beim
          n&auml;chsten Besuch wiederzuerkennen (sog. persistente Cookies).
          Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen
          Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten
          sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach
          einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie
          unterscheiden kann. Die Dauer der jeweiligen Cookie-Speicherung
          k&ouml;nnen Sie der &Uuml;bersicht zu den Cookie-Einstellungen Ihres
          Webbrowsers entnehmen.
        </p>

        <p>
          Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen
          den Bestellprozess zu vereinfachen (z.B. Merken des Inhalts eines
          virtuellen Warenkorbs f&uuml;r einen sp&auml;teren Besuch auf der
          Website). Sofern durch einzelne von uns eingesetzte Cookies auch
          personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
          gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO zur Durchf&uuml;hrung des
          Vertrages, gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO im Falle einer
          erteilten Einwilligung oder gem&auml;&szlig; Art. 6 Abs. 1 lit. f
          DSGVO zur Wahrung unserer berechtigten Interessen an der
          bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer
          kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
        </p>

        <p>
          Bitte beachten Sie, dass Sie Ihren Browser so einstellen k&ouml;nnen,
          dass Sie &uuml;ber das Setzen von Cookies informiert werden und
          einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von
          Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en
          k&ouml;nnen. Jeder Browser unterscheidet sich in der Art, wie er die
          Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemen&uuml; jedes
          Browsers beschrieben, welches Ihnen erl&auml;utert, wie Sie Ihre
          Cookie-Einstellungen &auml;ndern k&ouml;nnen. Diese finden Sie
          f&uuml;r die jeweiligen Browser unter den folgenden Links:
        </p>

        <p>
          Internet Explorer:
          https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
          <br />
          Firefox:
          https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
          <br />
          Chrome:
          https://support.google.com/chrome/answer/95647?hl=de&amp;hlrm=en
          <br />
          Safari:
          https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14
          <br />
          Opera: https://help.opera.com/de/latest/web-preferences/#cookies
        </p>

        <p>
          Bitte beachten Sie, dass bei Nichtannahme von Cookies die
          Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.
        </p>

        <h3>4) Kontaktaufnahme</h3>

        <p>
          Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
          E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle
          eines Kontaktformulars erhoben werden, ist aus dem jeweiligen
          Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich
          zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die
          Kontaktaufnahme und die damit verbundene technische Administration
          gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung
          dieser Daten ist unser berechtigtes Interesse an der Beantwortung
          Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt
          Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist
          zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs.
          1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender
          Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich
          aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene
          Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen.
        </p>

        <h3>5) Online-Terminvereinbarung</h3>

        <p>
          Wir verarbeiten Ihre personenbezogenen Daten im Rahmen der zur
          Verf&uuml;gung gestellten Online-Terminvereinbarung. Welche Daten wir
          zur Online-Terminvereinbarung erheben, k&ouml;nnen Sie aus dem
          jeweiligen Eingabeformular bzw. der Terminabfrage zur
          Terminvereinbarung ersehen. Sofern gewisse Daten notwendig sind, um
          eine Online-Terminvereinbarung durchf&uuml;hren zu k&ouml;nnen, machen
          wir diese im Eingabeformular bzw. bei der Terminabfrage entsprechend
          kenntlich. Sofern wir Ihnen ein Freitextfeld beim Eingabeformular zur
          Verf&uuml;gung stellen, k&ouml;nnen Sie dort Ihr Anliegen n&auml;her
          beschreiben. Sie k&ouml;nnen dann auch selbst steuern, welche Daten
          Sie zus&auml;tzlich eintragen m&ouml;chten.
        </p>

        <p>
          Ihre mitgeteilten Daten werden ausschlie&szlig;lich zum Zweck der
          Terminvereinbarung gespeichert und verwendet. Bei der Verarbeitung von
          personenbezogenen Daten, die zur Erf&uuml;llung eines Vertrages mit
          Ihnen erforderlich sind (dies gilt auch f&uuml;r
          Verarbeitungsvorg&auml;nge, die zur Durchf&uuml;hrung vorvertraglicher
          Ma&szlig;nahmen erforderlich sind), dient Art. 6 Abs. 1 lit. b DSGVO
          als Rechtsgrundlage. Haben Sie uns eine Einwilligung f&uuml;r die
          Verarbeitung Ihrer Daten erteilt, erfolgt die Verarbeitung auf
          Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Eine erteilte Einwilligung
          kann jederzeit durch eine Nachricht an den zu Beginn dieser
          Erkl&auml;rung genannten Verantwortlichen widerrufen werden.
        </p>

        <h3>6) Webanalysedienste</h3>

        <p>Google (Universal) Analytics</p>

        <p>
          Google (Universal) Analytics
          <br />
          Diese Website benutzt Google (Universal) Analytics, einen
          Webanalysedienst der Google Ireland Limited, Gordon House, 4 Barrow
          St, Dublin, D04 E5W5, Irland (&quot;Google&quot;). Google (Universal)
          Analytics verwendet sog. &quot;Cookies&quot;, das sind Textdateien,
          die auf Ihrem Computer gespeichert werden und die eine Analyse Ihrer
          Nutzung der Website erm&ouml;glichen. Die durch das Cookie erzeugten
          Informationen &uuml;ber Ihre Benutzung dieser Website
          (einschlie&szlig;lich der gek&uuml;rzten IP-Adresse) werden in der
          Regel an einen Server von Google &uuml;bertragen und dort gespeichert,
          hierbei kann es auch zu einer &Uuml;bermittlung an die Server der
          Google LLC. in den USA kommen.
          <br />
          Diese Website verwendet Google (Universal) Analytics
          ausschlie&szlig;lich mit der Erweiterung &quot;_anonymizeIp()&quot;,
          die eine Anonymisierung der IP-Adresse durch K&uuml;rzung sicherstellt
          und eine direkte Personenbeziehbarkeit ausschlie&szlig;t. Durch die
          Erweiterung wird Ihre IP-Adresse von Google innerhalb von
          Mitgliedstaaten der Europ&auml;ischen Union oder in anderen
          Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen
          Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird
          die volle IP-Adresse an einen Server von Google LLC.in den USA
          &uuml;bertragen und dort gek&uuml;rzt. In diesen Ausnahmef&auml;llen
          erfolgt diese Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
          auf Grundlage unseres berechtigten Interesses an der statistischen
          Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken.
          <br />
          In unserem Auftrag wird Google diese Informationen benutzen, um Ihre
          Nutzung der Website auszuwerten, um Reports &uuml;ber die
          Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns
          gegen&uuml;ber zu erbringen. Die im Rahmen von Google (Universal)
          Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht
          mit anderen Daten von Google zusammengef&uuml;hrt.
          <br />
          Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
          s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich nutzen
          k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der
          durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
          Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
          Daten durch Google verhindern, indem Sie das unter dem folgenden Link
          verf&uuml;gbare Browser-Plugin herunterladen und installieren:
          <br />
          https://tools.google.com/dlpage/gaoptout?hl=de
          <br />
          Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen
          Ger&auml;ten klicken Sie bitte auf den folgenden Link, um ein
          Opt-Out-Cookie zu setzen, das die Erfassung durch Google Analytics
          innerhalb dieser Website zuk&uuml;nftig verhindert (dieses
          Opt-Out-Cookie funktioniert nur in diesem Browser und nur f&uuml;r
          diese Domain. Wenn Sie Ihre Cookies in diesem Browser l&ouml;schen,
          m&uuml;ssen Sie diesen Link erneut anklicken):{" "}
          <button
            onClick="gaOptout()"
          >
            Google Analytics deaktivieren
          </button>
          <br />
          Weitere Hinweise zu Google (Universal) Analytics finden Sie hier:
          https://policies.google.com/privacy?hl=de&amp;gl=de
          <br />
          F&uuml;r den Fall der &Uuml;bermittlung von personenbezogenen Daten an
          die Google LLC. mit Sitz in den USA, hat sich Google LLC. f&uuml;r das
          us-europ&auml;ische Datenschutz&uuml;bereinkommen &bdquo;Privacy
          Shield&ldquo; zertifiziert, welches die Einhaltung des in der EU
          geltenden Datenschutzniveaus gew&auml;hrleistet. Ein aktuelles
          Zertifikat kann hier eingesehen werden:
          https://www.privacyshield.gov/list
          <br />
          Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten
          Verarbeitung Ihrer Daten Ihre Einwilligung gem&auml;&szlig; Art. 6
          Abs. 1 lit. a DSGVO eingeholt. Sie k&ouml;nnen Ihre erteilte
          Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um
          Ihren Widerruf auszu&uuml;ben, befolgen Sie bitte die vorstehend
          geschilderte M&ouml;glichkeit zur Vornahme eines Widerspruchs.
        </p>

        <h3>7) Tools und Sonstiges</h3>

        <p>
          - Adobe Fonts (Typekit)
          <br />
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts die von der Adobe Systems Incorporated, 345 Park
          Avenue, San Jose, CA 95110-2704, USA (&bdquo;Adobe&ldquo;)
          bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser
          die ben&ouml;tigten Web Fonts in ihren Browser-Cache, um Texte und
          Schriftarten korrekt anzuzeigen.
          <br />
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern von Adobe aufnehmen. Hierbei kann es auch zu einer
          &Uuml;bermittlung von personenbezogenen Daten an die Server von Adobe
          in den USA kommen. Auf diese Weise erlangt Adobe Kenntnis
          dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse unsere Website aufgerufen
          wurde. Die Nutzung von Adobe Fonts erfolgt im Interesse einer
          einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird
          eine Standardschrift von Ihrem Computer genutzt.
          <br />
          F&uuml;r den Fall der &Uuml;bermittlung von personenbezogenen Daten an
          Adobe mit Sitz in den USA, hat sich Adobe f&uuml;r das
          us-europ&auml;ische Datenschutz&uuml;bereinkommen &bdquo;Privacy
          Shield&ldquo; zertifiziert, welches die Einhaltung des in der EU
          geltenden Datenschutzniveaus gew&auml;hrleistet. Ein aktuelles
          Zertifikat kann hier eingesehen werden:
          https://www.privacyshield.gov/list
          <br />
          Weitere Informationen zu Adobe Fonts finden Sie unter
          https://fonts.adobe.com/ und in der Datenschutzerkl&auml;rung von
          Adobe: https://www.adobe.com/de/privacy.html
        </p>

        <h3>8) Rechte des Betroffenen</h3>

        <p>
          <b>8.1</b>&thinsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen
          gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung
          Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts-
          und Interventionsrechte), &uuml;ber die wir Sie nachstehend
          informieren:
        </p>

        <ul>
          <li>
            Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben
            insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns
            verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die
            Kategorien der verarbeiteten personenbezogenen Daten, die
            Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber
            denen Ihre Daten offengelegt wurden oder werden, die geplante
            Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der
            Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
            L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch
            gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde,
            die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen
            erhoben wurden, das Bestehen einer automatisierten
            Entscheidungsfindung einschlie&szlig;lich Profiling und ggf.
            aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik
            und die Sie betreffende Tragweite und die angestrebten Auswirkungen
            einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung,
            welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung
            Ihrer Daten in Drittl&auml;nder bestehen;
          </li>
          <li>
            Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben ein
            Recht auf unverz&uuml;gliche Berichtigung Sie betreffender
            unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns
            gespeicherten unvollst&auml;ndigen Daten;
          </li>
          <li>
            Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben
            das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei
            Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen.
            Dieses Recht besteht jedoch insbesondere dann nicht, wenn die
            Verarbeitung zur Aus&uuml;bung des Rechts auf freie
            Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung
            einer rechtlichen Verpflichtung, aus Gr&uuml;nden des
            &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung
            oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;
          </li>
          <li>
            Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art.
            18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die
            von Ihnen bestrittene Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft
            wird, wenn Sie eine L&ouml;schung Ihrer Daten wegen
            unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen die
            Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen, wenn Sie
            Ihre Daten zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese Daten nach
            Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie Widerspruch
            aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben, solange
            noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde
            &uuml;berwiegen;
          </li>
          <li>
            Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie
            das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung
            der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend
            gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die
            Sie betreffenden personenbezogenen Daten offengelegt wurden, diese
            Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung
            der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
            unm&ouml;glich oder ist mit einem
            unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht
            das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.
          </li>
          <li>
            Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO:
            Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns
            bereitgestellt haben, in einem strukturierten, g&auml;ngigen und
            maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an
            einen anderen Verantwortlichen zu verlangen, soweit dies technisch
            machbar ist;
          </li>
          <li>
            Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7
            Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung
            in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die
            Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die
            betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine
            weitere Verarbeitung nicht auf eine Rechtsgrundlage zur
            einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den
            Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            nicht ber&uuml;hrt;
          </li>
          <li>
            Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der
            Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben
            Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer
            Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres
            Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des
            mutma&szlig;lichen Versto&szlig;es.
          </li>
        </ul>

        <p>
          <b>8.2</b>&thinsp;WIDERSPRUCHSRECHT
        </p>

        <p>
          WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE
          PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN
          BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT,
          AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
          GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT
          EINZULEGEN.
          <br />
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
          VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
          ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
          F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN,
          GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE
          VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
          RECHTSANSPR&Uuml;CHEN DIENT.
        </p>

        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
          GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
          ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH
          WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.
        </p>

        <p>
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
          VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
        </p>

        <h3>9) Dauer der Speicherung personenbezogener Daten</h3>

        <p>
          Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
          anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und
          &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der
          jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und
          steuerrechtliche Aufbewahrungsfristen).
        </p>

        <p>
          Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
          ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a
          DSGVO werden diese Daten so lange gespeichert, bis der Betroffene
          seine Einwilligung widerruft.
        </p>

        <p>
          Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im
          Rahmen rechtsgesch&auml;ftlicher bzw.
          rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage
          von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten
          nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig
          gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
          Vertragsanbahnung erforderlich sind und/oder unsererseits kein
          berechtigtes Interesse an der Weiterspeicherung fortbesteht.
        </p>

        <p>
          Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
          bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO
          aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige
          Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die die Interessen,
          Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die
          Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung
          von Rechtsanspr&uuml;chen.
        </p>

        <p>
          Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
          Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
          diese Daten so lange gespeichert, bis der Betroffene sein
          Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.
        </p>

        <p>
          Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung
          &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt,
          werden gespeicherte personenbezogene Daten im &Uuml;brigen dann
          gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben
          oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
        </p>
      </Section>
    </Layout>
  )
}

export default DataPolicyPage
